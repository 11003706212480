import React from "react";
import {
  PageHTML,
  ZWrapper,
} from '../../ui-components';
import styled from "styled-components";


const GridContacts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    'studio manufacturing';
  grid-column-gap: 24px;  
  @media ${({ theme }) => theme.device.mobile}{
    grid-template-columns: 1fr;
    grid-template-areas: 
      'studio'
      'manufacturing';
    text-align: center;
    grid-column-gap: 0;  

  }
`;

const StudioContacts = styled.div`
  grid-area: studio;
`;

const ManufacturingContacts = styled.div`
  grid-area: manufacturing;
`;

const TextContacts = styled.div`
  grid-area: text;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 250px;

  @media ${({ theme }) => theme.device.phone}{
    height: 250px;
  }
`


const ContactsGrid = ({ html, googleMapsZStudio, googleMapsZFactory }) => (

  <GridContacts>
    <StudioContacts>
      <ZWrapper>
        <Iframe
          frameBorder="0" allowFullScreen
          src={googleMapsZStudio}
        >
        </Iframe>
      </ZWrapper>

      <PageHTML dangerouslySetInnerHTML={{ __html: html[0] }} center />
    </StudioContacts>

    <ManufacturingContacts>
      <ZWrapper angle={6}>
        <Iframe
          frameBorder="0" allowFullScreen
          src={googleMapsZFactory}
        >
        </Iframe>
      </ZWrapper>
      <PageHTML dangerouslySetInnerHTML={{ __html: html[1] }} center />
    </ManufacturingContacts>
    <TextContacts>
    </TextContacts>
  </GridContacts >
)

export default ContactsGrid;
