import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';
import ContactsGrid from "../components/contacts-grid/contacts-grid";

const ContactsPage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html.split("<hr>");



  return (
    <>
      <SEO
        canonical="/contacts/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>
          <PageTitle>{content.title}</PageTitle>

          <ContactsGrid
            html={html}
            googleMapsZStudio="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2146.232343109113!2d25.999685415564343!3d57.457345166713026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ebc9493c68fd71%3A0x52af3b680870e6e0!2sZELTINI+SIA!5e0!3m2!1sen!2sgr!4v1554794604502!5m2!1sen!2sgr"
            googleMapsZFactory="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2141.80502824394!2d25.4115318!3d57.5327336!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2f683752f1ec638b%3A0x9964f6335eb468d8!2sZ-TRITON!5e0!3m2!1sen!2slv!4v1642171191020!5m2!1sen!2slv"
          />


          <PageHTML dangerouslySetInnerHTML={{ __html: html[2] }} center />


        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}



export default ContactsPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/contacts.md/"}) {
    frontmatter {
      title
    }
    html
  }
}  
`;